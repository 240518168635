// src/components/Tabs.js

import React, { useState } from 'react';
import styled from 'styled-components';
import imgContent1 from "../assets/image/jpeg/mina.jpg";
import imgContent2 from "../assets/image/jpeg/ghufran.jpg";
import { Container, Row, Col } from "react-bootstrap";
import { Section } from "../components/Core";
import trust from "../assets/image/png/trust.svg";

const TabsContainer = styled.div`

  display: flex;
  justify-content: center; /* Center the tabs horizontally */
  max-width: 700px; /* Set a maximum width for the container */
  margin: 0 auto; /* Center the container horizontally */
`;

const Tab = styled.div`
  position: relative;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  margin: 5px;
  box-sizing: border-box; /* Include padding in the width calculation */
  transition: background-color 0.3s ease;

  &:not(:last-child) {
    margin-right: 10px; /* Add some spacing between tabs */
  }

  &:hover {
    background-color: #f7f7fb; /* Background color on hover */
  }

  &.active {
    background-color: #006b7b; /* Background color for the active tab */
     p{
        
        color: #ffffff !important; /* Text color for the active tab */
    padding-top:10px;
    font-size:20px;

    }
    
  }

  p {
    margin-bottom: 0; /* Remove default margin for paragraphs */
    font-size: 16px; /* Adjust the font size */
  }

  img {
    width: 100%; /* Make the image cover the entire tab area */
    height: auto; /* Maintain the aspect ratio */
    border-radius: 5px; /* Add some border-radius for a rounded look */
  }
`;

const Content = styled.div`
  display: ${props => (props.isActive ? 'block' : 'none')};
  padding:15px;

p{
font-size:14px;
}
  @media screen and (min-width: 768px)
 {
    background:#f7f7fb;
 padding-left:100px;
 padding-right:100px;
 padding-top:60px;
 padding-bottom:60px;
 margin-left:100px;
 margin-right:100px;
 border-radius:10px;
 p{
 font-size:16px;
 }
}
`;

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;


const Tabs = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = tabNumber => {
    setActiveTab(tabNumber);
  };

  return (
    <>
          {/* <!-- Content section 2 --> */}
          <Section py={4} bg="#ffffff">
          <Container className='pt-3'>
    <Row>
       
        <Iwrap>

<Itext as="h4">Meet The <span>Team</span></Itext>
</Iwrap><br />
       
    </Row>
    </Container>
</Section>

      <TabsContainer>
        <Tab onClick={() => handleTabClick(1)} className={activeTab === 1 ? 'active' : ''}>
          <img src={imgContent1} alt="Image 1" />
          <p>Mina</p>
        </Tab>
        <Tab onClick={() => handleTabClick(2)} className={activeTab === 2 ? 'active' : ''}>
          <img src={imgContent2}  alt="Image 2" />
          <p>Dr Gufran</p>
        </Tab>
      </TabsContainer>
      <Content isActive={activeTab === 1}>
      <Iwrap>


<Itext as="h4">Mina - <span>Leading Cosmetics Professional</span></Itext>
</Iwrap>
        <p>Mina is a professional independent prescriber, clinical pharmacist and aesthetic practitioner. Providing excellence in non-surgical treatments and positivity in skin ageing; Mina is our co-founder and heart of the company. 
               Passionate to enhance the beauty and confidence of her patients by providing natural looking results, that elevate ones natural beauty, tailored to the patient’s individual suitability and needs.<br /><br />
Mina has true passion and expertise in the field of aesthetics and this is reflected in all her work. She has a vast portfolio of amazing results all performed without surgery. Before any treatment has begun, she will always provide an honest assessment to ensure you understand all the steps of the procedure.

Mina specialises in dermal fillers, anti-wrinkle injections, mesotherapy and much more. After every treatment, Mina strives to build a long lasting relationship with all her patients, to ensure they are truly happy with their results.<br /><br />

You will be guided with expert care, in a bespoke and comfortable environment.

 <i>“Mina's integrity is truly admirable, I could tell that my wellbeing was her priority as she was honest about what I could realistically achieve with the treatment, and she never tried to upsell me any other treatments. She also took the time to understand what I was trying to achieve and explained the procedure thoroughly. During the treatment she was very gentle and we had a lovely chat. As per the results, I'm very satisfied, I got exactly what I wanted“ - Gabriela</i></p>
 <a className="d-block d-sm-none pt-3 pb-3" href="https://uk.trustpilot.com/review/www.dermamina.com" target="_blank"> <img src={trust} width="65%" /></a>

<a className="pt-3 d-none d-sm-block 	d-sm-none d-md-block" href="https://uk.trustpilot.com/review/www.dermamina.com" target="_blank"> <img src={trust} width="67%" /></a>
      </Content>
      <Content isActive={activeTab === 2}>
      <Iwrap>


<Itext as="h4">Dr Gufran - <span>Aesthetics Doctor</span></Itext>
</Iwrap>
        <p>Dr. Gufran is not only recognized for her technical prowess but also for her unwavering commitment to patient satisfaction. With a compassionate approach, she actively listens to each patient, understanding their unique desires and concerns. This empathetic communication ensures that every individual receives a personalized treatment plan tailored to meet their specific needs and aspirations.<br /> <br />

By prioritizing the patient's voice in the decision-making process, Dr. Gufran establishes a collaborative and trusting relationship. This patient-centric approach reflects her dedication to not only achieving aesthetic excellence but also ensuring the overall happiness and confidence of those under her care.

<br /> <br />
Dr. Gufran specialises in dermal fillers, anti-wrinkle injections, hair loss, skin boosters and much more.
</p>
      </Content>
    </>
  );
};

export default Tabs;
