// src/components/ReadMore.js

import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  p{
    font-size:14px;
  }
  @media screen and (max-width: 767px)
     {
         
          font-size: 12px;
          
      }
`;

const HiddenText = styled.div`
  display: ${props => (props.isVisible ? 'block' : 'none')};
  margin-top: 10px;

`;

const HiddenFont = styled.p`
 font-size:10px;
 color: gold;
`;

const ReadMore = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <Container>
      <p>
        At Dermamina, we understand that the client always comes first. We ensure all products and services we provide are tailored to meet individual specific needs and expectations.{' '}
     <span style={{ cursor: 'pointer', color: 'rgb(171, 142, 102)' , fontSize: '12px' }} onClick={toggleVisibility}>
          {isVisible ? 'Show less' : 'Read more'}
        </span>
      </p>
      <HiddenText isVisible={isVisible}>
        <p>
        Bespoke service and results are what we strive to deliver. Our passionate team is dedicated to ensuring that our clients are fully satisfied, from the very first point of contact through to the treatment completion and aftercare.<br /> <br />
          We pride ourselves on our ability to carefully and accurately understand our clients' requirements, and to use this knowledge to provide the most relevant advice, products, and services possible.<br /> <br /> We believe in continuous learning and professional development to be able to provide the most effective, beneficial, latest, and evidence-based treatments to all our clients.<br /> <br />
        </p>
        <p>
          We are passionate about providing treatments and achieving results that will leave our clients not simply comfortable but with a renewed confidence in themselves.<br /> <br /> By putting the client at the centre of everything we do, we ensure that we not only achieve success in our own business but that we also help them achieve their desired outcome.
        </p>
      </HiddenText>
    </Container>
  );
};

export default ReadMore;
