import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";
import imgMobile from "../../assets/image/jpeg/mes-hero.jpg";
import Newarrow from '../../assets/image/jpeg/enter.svg';
import trust from '../../assets/image/png/trust.svg';


const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;


const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;


const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;


const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;

const IndexEthicsPage = ({setIsModalOpen}) => (
  <>
  <Separator />
    {/* <!-- Content section 2 --> */}
    <Section py={4} bg="#f7f7fb">
      <Container className="pt-lg-5 pb-lg-5 pt-3 pb-3">

 
        <Row className="align-items-center">
        <Col  data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
              data-aos-once="false" lg="6" md="9">
          <Box className=" pt-lg-0">
            <Iwrap>


              <Itext as="h2">Dermamina <span>Ethics</span></Itext>
            </Iwrap>
            <SecondText className="pb-4">
              You are in safe hands

            </SecondText>
            <Text>
              Dermamina is renowned for providing honest, transparent and quality services, with time and care always
              taken to ensure every valued patient receives a tailored treatment and result they deserve. We have true
              passion and expertise in the field of aesthetics and this is reflected in all our work, with a vast
              portfolio of amazing results all performed without surgery.

            </Text>
            <br />
            <Text>
              At Dermamina, we take a patient centric approach, providing in depth consultation before any treatment.
              Our focus is to gain a full understanding of the needs and expectations of the patient in order to deliver
              the best possible results, and to ensure that the patient is fully informed and involved before, during
              and after any treatment.

            </Text>

            <br />
            <Text>
              <b><i>“She has transparency about her. She does no mix her words or tries to sale herself. Her advice on
                treatments for you is honest and realistic. My treatment was painless and instantly felt it in my gut
                prior to my treatment that I am certainly in trustworthy professional hands”</i></b> – Turmeric
            </Text> <a href="https://uk.trustpilot.com/review/www.dermamina.com" target="_blank"> <img src={trust}
                                                                                                       width="150px" /></a>


            <br /> <br />

            <div onClick={() => setIsModalOpen(true)}
                 activeClassName="active"
            >
              <Bookbutton>
                Book Appointment
              </Bookbutton>
            </div>
            <br />

          </Box>
        </Col>


          <Col lg="6" className="pl-0 pr-0 ml-0 ml-0 mb-lg-0">
            <div
              className="pl-0"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="200"
              data-aos-once="false"
            >
              <img src={imgMobile} alt="What is non surgical rhinoplasty" className="shadow rounded d-none d-sm-block" />
            </div>
          </Col>
         
        </Row>
      </Container>
    </Section>
    <SeparatorEnd />
  </>
);

export default IndexEthicsPage;
